(function () {
  'use strict';

  /* @ngdoc object
   * @name entitats.newEntitat
   * @description
   *
   */
  angular
  .module('neo.home.entitats.newEntitat', [
    'ui.router',
    'ngMaterial'
  ]);
}());
